import React from 'react'
import { toast } from 'react-toastify';
import { deleteMarriageEdge } from '../../actions/deleteNodeAction';
import ToastMessage from './toast-message';

export default function DeleteMarriage ({selectedNode = [],setLoading}){

    const handleDeleteMarriage = async () => {
    
        setLoading(true);
    
        const deleteMarriageData = {
          marriage_node_to_delete: selectedNode.marriage_node_id,
          selected_node_id: selectedNode.uid,
          spouse_node_id: selectedNode?.spouse?.uid,
        }
    
        // const isSpouse = selectedPath.length == 0 ? true : false;
    
        try{
          const res = await deleteMarriageEdge(deleteMarriageData);
          // getFamilyDetails(selectedNode,selectedPath,isSpouse);
          setLoading(false);
          if(res.status){
            toast.success(`Spouse - ${selectedNode?.spouse?.name} of ${selectedNode.name} marriage node deleted successfully`);
            toast.info(<ToastMessage message = {'Click Okay to reload page to reflect changes'} refreshRequired = {true}/>,{autoClose: false});
          }
          else
            toast.error(`Spouse - ${selectedNode?.spouse?.name} of ${selectedNode.name} could not be deleted\n Error: ${res.error_message}`);
        }catch(error){
          console.log(`Spouse - ${selectedNode?.spouse?.name} of ${selectedNode.name} could not be deleted`,error);
          toast.error(`Spouse - ${selectedNode?.spouse?.name} of ${selectedNode.name} could not be deleted\n Error: ${error}`);
          setLoading(false);
        }
      }

  return (
    <button
   
    onClick= {
      ()=>{
        if (window.confirm(`Are you sure you want to delete marriage of ${selectedNode.name}`))
        {
          handleDeleteMarriage()
        } 
      }
    }
    >
      Remove Marriage Node
      </button>
  )
}
